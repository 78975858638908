import React,{useState, useEffect} from 'react'
import styled from 'styled-components';
import {Accordion, Card, Button,Modal, Table, Row, Col} from 'react-bootstrap';
import Select from 'react-select'
import {Link} from 'gatsby';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import EdiText from 'react-editext'
import { FaPlus, FaPencilAlt, FaWindowClose, FaCheck, FaSearch, FaGripVertical, FaTrashAlt } from "react-icons/fa";
import searchImg from '../../assets/search.svg'
import { getDefaultTemplates, getDefaultCategories, getDocumentByID, updateProjectInfo, addDocOrderId} from '../../graphql/queries';
import { useSelector, useDispatch } from "react-redux";
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import * as queryString from "query-string";
import Tick from "../../assets/tickbox.svg";
import Uncheck from "../../assets/checkmark 2.svg";
import moment from 'moment'
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

export default function DocReport(props) {
    const {consoleLog} = require('../commonFunctions.js');
    consoleLog("DocReport props: ", props);
    const [search_result, SetSearchResult] = React.useState(props.search_result);
    const [selectReport, SetSelectReport] = React.useState(false);
    const [updateQty, setUpdateQty] = React.useState(false);
    const [selectedStarterTemplates, setSelectedStarterTemplates] = React.useState('');
    const [modalShow, setModalShow] = React.useState(false);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    async function updateQuantities(){
        consoleLog("updateQuantities props", props);
        consoleLog("updateQuantities updateQty", updateQty);

        let orderData = props.search_result;

        props.setSelectedReports(oldSelectedReports=>(
            [ ...oldSelectedReports, {
                id: orderData.order_id,
                report_type: orderData.scope_type_id,
                name: orderData.name,
                scope_id: orderData.scope_id
                }
            ]
        ));

        props.setSearchResults((oldSearchResults)=>(
            oldSearchResults.map(result=>{
                if(result.order_id == props.search_result.order_id){
                    result.present_in_doc = true;
                }
                return result;
            })
        ));

        closeModal();
        setModalShow(false);
        
        let scopeTypeId = orderData.scope_type_id;
        let scopeIds = [orderData.scope_id];
        let scopeTypes = [scopeTypeId];
        
        if(scopeTypeId === 1 || scopeTypeId === 4 || scopeTypeId === 12 || scopeTypeId === 17) {
            scopeTypes = [1, 4, 12, 17];
        }

        for(const report of props.selectedReports) {

            if(scopeTypes.includes(report.report_type) && scopeIds.indexOf(report.scope_id) === -1) {
                scopeIds.push(report.scope_id);
            }
        }
        props.updateDocOrders(orderData.order_id, scopeTypeId, scopeIds, 'update');    
    }

    function handleSelectStarterTemplate(e){
        consoleLog("handleSelectStarterTemplate: ", e);
        openModal();
        setModalShow(true)
        SetSelectReport(!selectReport);
        setSelectedStarterTemplates(e.template_id);
    }

    return (
        <Controller>
        { modalShow && <Modal
          {...props}
          onHide={() =>setModalShow(true)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          style={{opacity:'1'}}
          
        >
          <Modal.Header>
         
            <Modal.Title id="contained-modal-title-vcenter">
                <p className="modal-title-x mb-0">Are You Sure?</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
                <p className="text-center p-2">Are you sure you want to add the <strong>{search_result.name}</strong> ?</p>
                <p className="tex-left pt-2 pl-2 color-red">
                    <input type="checkbox" onChange={() => setUpdateQty(!updateQty)} checked={updateQty} name="update_qty" />
                    <span className="ml-3" style={{color:'#f7941c'}}>Update Quantities for all line items</span>
                </p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="modal-btn-x" onClick={updateQuantities}>Yes</Button>
            <Button className="modal-btn-x" onClick={()=>setModalShow(false)}>No</Button>
          </Modal.Footer>
        </Modal> }

        <Row>
        <table>
            <thead></thead>
            <tbody>
                <tr className="custom-table-p row" onClick={() => props.search_result?.present_in_doc !== true ? handleSelectStarterTemplate(search_result) : ''}>
                    <div className="col-2" style={{color:"#00daa1"}}>
                        { props.search_result.present_in_doc && <FaCheck className="TickIcon"/> }
                    </div>
                    <div className="col-2">
                        <img src={require(`../../assets/reports/circle-${search_result.scope_type_id}.png`).default}  alt="" style={{width:'16px', height:'16px'}}/>                
                    </div>
                    <div className="col-8">
                        <div className="starter_template_detail text-left">
                            <p style={{cursor:"pointer"}}><strong>{search_result.scope_type_id == 1 && 'RoofScope'}{search_result.scope_type_id == 4 && 'GutterScope'}{search_result.scope_type_id == 11 && 'PaintScope'}{search_result.scope_type_id == 6 && 'SidingScope'}{search_result.scope_type_id == 7 && 'InsulationScope'}{search_result.scope_type_id == 10 && 'ConcreteScope'}{search_result.scope_type_id == 12 && 'RoofScopeX'}{search_result.scope_type_id == 17 && 'RoofScope+'}</strong></p>
                        </div>
                    </div>
                </tr>
                <tr className="custom-width-tr" style={{cursor:"pointer"}} onClick={() => props.search_result?.present_in_doc !== true ? handleSelectStarterTemplate(search_result) : ''}>
                    <p>
                        {search_result.name}
                    </p>
                </tr>
                <tr className="custom-width-tr">
                    <p>
                        {moment(search_result.date_updated).format('MMMM Do YYYY')}
                    </p>
                </tr>
                <tr className="custom-width-tr">
                    <p>
                        <span><strong>{  search_result.size + " " + search_result.size_unit } </strong></span> 
                        <span>{ ", " + search_result.buildings + " Bldg"}</span>
                    </p>
                </tr>
                <tr className="custom-table-p">
                    <p className=" view_pdf" style={{color:"#F7941C"}}><a href={search_result.pdf} target="_blank">VIEW PDF</a></p>
                </tr>
            </tbody>
        </table>
        </Row>
        {/* <Row className="m-0">
            <Col md={12} className="search-result" onClick={() => handleSelectStarterTemplate(search_result) }>
                <Col md={6} className="label-col">
                    { selectReport && 
                       <img src={Tick}  className="TickIcon" alt=""/>
                    }
                    <b className="fa fa-plus on-new"></b>
                    <div className="starter_template_detail pl-5">
                        <p>{search_result.name}</p>
                    </div>
                </Col>
                <Col md={6} className="label-col">
                    <Col md={5} className="label-col">
                        <p>
                        {moment(search_result.date_updated).format('MMMM Do YYYY')}
                        </p>
                    </Col>
                    <Col md={7} className="label-col text-right">
                        <p>
                            <span><strong>{  search_result.size + " " + search_result.size_unit } </strong></span> 
                            <span>{ ", " + search_result.buildings + " Bldg"}</span>
                            <span className="ml-4 view_pdf"><Link to="#">View PDF</Link></span>
                        </p>
                    </Col>
                </Col>
            </Col>
        </Row> */}

        </Controller>
    );
}



const Controller = styled.div`

.custom-table-p{
    padding:0 40px;
    text-align:left;
    width:350px;
    @media(max-width:600px){
        width:100%;
        text-align:center;
    }
}
.custom-width-tr{
    width:300px;
    text-align:left;
    @media(max-width:600px){
        text-align:center;
        width:100%
    }
}

table {
      border-bottom: 1px solid #ccc;
      border-collapse: collapse;
      margin: 0;
      padding: 0;
      width: 100%;
      table-layout: fixed;
      }
      thead{
          border:none;
      }
      tbody{
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding:10px;
      }

      table caption {
      font-size: 1.5em;
      margin: .5em 0 .75em;
      }

      table tr {
      background-color: #fff;
      padding: .35em;
      }

      table th,
      table td {
      padding: .625em;
      text-align: center;
      }

      table th {
      font-size: .85em;
      letter-spacing: .1em;
      text-transform: uppercase;
      }

      @media screen and (max-width: 600px) {
      table {
        border-bottom: 1px solid #ccc;
      }

      tbody{
          flex-direction:column;
      }

      table caption {
         font-size: 1.3em;
      }
      
      table thead {
         border: none;
         clip: rect(0 0 0 0);
         height: 1px;
         margin: -1px;
         overflow: hidden;
         padding: 0;
         position: absolute;
         width: 1px;
      }
      
      table tr {
        
         display: block;
         margin-bottom: .625em;
      }
      
      table td {
         border-bottom: 1px solid #ddd;
         display: block;
         font-size: .8em;
         text-align: right;
      }
      
      table td::before {
         /*
         * aria-label has no advantage, it won't be read inside a table
         content: attr(aria-label);
         */
         content: attr(data-label);
         float: left;
         font-weight: bold;
         text-transform: uppercase;
      }
      
      table td:last-child {
         border-bottom: 0;
      }
    }

`

const ModalSetting = styled.div`

    .modal_header{
        line-height: 1em;
        padding: .7em;
        background: #6a6e6f;
        color: white;
        font-weight: 600;
        text-align: left;
    }
    .modal_body{
        padding: 20px;
        font-size: medium;
    }
    .color-red{
        color: red;
    }
    .modal_footer{
        padding: 20px;
    }
    .footer_btn{
        font-weight: 600;
        text-transform: uppercase;
        color: white;
        background: #ffffff;
        color: #000000;
        border-radius: 3px;
        padding: 1rem 7rem;
        box-sizing: border-box;
        cursor: pointer;
    }
    .btnUpdateQty{
        color: #ffffff;
        background: red;
    }
`

const customStyles = {
    content: {
        padding: '0px',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '30%',
        border: 'none',
        boxShadow: '0 0 15px rgb(0 0 0 / 40%'
    },
};