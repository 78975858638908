import React, { useState } from 'react'
import { Button, Modal, } from 'react-bootstrap';
import "./Modals.css";

export default function RemoveDocOrders(props) {
	return (
		<Modal
			onHide={() => props.setModalShow(true)}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			show={props.show}
			style={{ opacity: '1' }}
		>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">
					<p className="modal-title-x mb-0">Are You Sure?</p>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p className="text-center p-2">Are you sure you want to remove the <br /> {props.type}  <strong>{props.name}</strong> ?</p>
				<p className="tex-left pt-2 pl-2 color-red">
					<input type="checkbox" onChange={() => props.setUpdateQty((qty)=>!qty)} checked={props.updateQty} name="update_qty" />
					<span className="ml-3" style={{ color: '#f7941c' }}>Update Quantities for all line items</span>
				</p>
			</Modal.Body>
			<Modal.Footer>
				<Button className="modal-btn-x" onClick={props.removeOrder}> Yes </Button>
				<Button className="modal-btn-x" onClick={() => props.setModalShow(false)}>No</Button>
			</Modal.Footer>
		</Modal>
	);
}
